import React from "react";
import {
  BPLogo,
  Gradient,
  Layout,
  LeftSideContainer,
  RightSideContainer,
} from "./Layout";
import GRADIENT from "assets/corner_gradient.png";

const MainLayout = ({ children }) => {
  return (
    <Layout>
      <LeftSideContainer>
        <BPLogo src="/static/images/logo.svg" />
        <h2>Senior Inspired Government & Lifestyle App</h2>
      </LeftSideContainer>

      <RightSideContainer>
        {children}
        <Gradient src={GRADIENT} />
      </RightSideContainer>
    </Layout>
  );
};

export default MainLayout;
