import {  Button, Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Title from 'antd/lib/typography/Title';
import ProgressOverlay from 'components/progress/ProgressOverlay';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCitizen, getCitizenByCardNumber } from 'services/citizen';
import styled from 'styled-components';
import { useZxing } from "react-zxing";
import { StyledElement as BackgroundContainer } from "pages/SocialService/components/reusables";

const QrScanner = styled.video`
  width: 313px;
  height: 350px;
  object-fit: cover;
  z-index: 2;
  border-radius: 20px;
`;

const Header = styled(Title)`
  color: white !important;
  margin: 0 !important;
  text-align: left;
`;

const PageTitle = styled(Header)`
  padding: 25px 0px;
  text-align: center;
`


const FormContainer = styled.div`
  background-color: #303F9F;
  text-align: center;
  height: calc(100vh - 165px);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 100vw;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100vw;

  .button-search-by {
    display: flex;
    height: 43px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    span {
      font-size: 90%;
      color: #FFFFFF;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;
const SearchButton = styled(Button)`
  display: flex;
  width: 284px;
  height: 43px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  z-index: 2;
  margin-top: 15px;
  border-radius: 10px;
  border: 1px solid #FF7103;
  background: #FF7103 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
  color: #FFFFFF;
  span {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.38px;
  }
`;

const IdSearchInput = styled(Input)`
  margin-top: 10px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1.5px solid rgba(207, 207, 213, 0.50);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);

  color: #E7E7EA;

  font-family: Gilmer-Bold;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.327px;
`;


const Container = styled(BackgroundContainer)`
  height: 100vh;
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;
`;


const SearchForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [pausedScanner, setPausedScanner] = useState(false);
  const onSearch = async (values) => {
    try {
      setIsLoading(true);
      const idCardNumber = values.idCardNumber;

      if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(idCardNumber)) {
        const citizen = await getCitizen(idCardNumber);
        setIsLoading(false);
        setError('');
        navigate(`/citizen/${citizen.id}`);
      } else {
        const citizen = await getCitizenByCardNumber(idCardNumber);
        setIsLoading(false);
        setError('');
        navigate(`/citizen/${citizen.id}`);
      }


    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setError('No citizen data found');
    }
  }

  const onFinishFailed = errorInfo => {
    setError('There was an error');
    console.error("Failed:", errorInfo);
  };

  const { ref } = useZxing({
    onError: error => {
      setError(error.message);
      setPausedScanner(true)
    },
    paused: pausedScanner,
    timeBetweenDecodingAttempts: 1000,
    constraints: {
      video: {
        facingMode: "environment",
      },
    },
    onDecodeResult(result) {
      if (result) {
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(result.getText())) {
          navigate(`/citizen/${result.getText()}`);
        } else {
          ``;
          setError({
            message: "Error",
            description: "Invalid QR Code",
          });
        }
      }
    },
  });

  return (
    <Container>
      {
        isLoading && <ProgressOverlay />
      }
      {
        error && <ProgressOverlay
          title="Error"
          titleProps={{
            type: 'danger'
          }}
          description={error}
          onClose={() => setError(null)}
          isSpin={false}
        />
      }
      <FormContainer>
        <PageTitle level={3}>Scan QR Code</PageTitle>
      <QrScanner ref={ref} />
      <FormWrapper>
        <Header level={4}>Couldn&apos;t find Citizen?</Header>
        <Header level={5}>Enter ID number instead.</Header>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            minWidth: "90%",
          }}
          onFinish={onSearch}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            idCardNumber: ''
          }}
        > <FormItem name="idCardNumber">
            <IdSearchInput placeholder="Enter ID Number"
              onChange={e =>
                form.setFieldsValue((values) => ({
                  ...values,
                  idCardNumber: e.target.value
                }))
              }
            />
          </FormItem>
          <FormItem>
            <SearchButton htmlType="submit" block>
              Search Citizen
            </SearchButton>
          </FormItem>
          <FormItem>
            <Button
              size='small'
              className="button-search-by"
              type="text"
              onClick={() => navigate('/search')}
              block
            >
              Search by Last Name
            </Button>
          </FormItem>
        </Form>
      </FormWrapper>
      </FormContainer>
    </Container>
  );
};
export default SearchForm
