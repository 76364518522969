const { styled } = require("styled-components");

export const StyledElement = styled.div`
  background-image: url(/static/images/logo.svg);
  background-repeat: no-repeat;
  background-size: calc(100% - 170px) auto;
  background-position: center calc((165px / 2) - 25px);
  padding-top: 165px;
  background-color: #F8F8F8;

  @media only screen and (min-width: 768px) {
    background: none;
    padding-top: 0px;
    box-shadow: none;
    margin-top: 0px;
  }
`;

export const StyledElement2 = styled.div`
  background-color: #F8F8F8;
  background-image: url(/static/images/logo.svg);
  background-repeat: no-repeat;
  background-size: 55% auto;
  background-position: center 40px;
  padding-top: 165px;

  @media only screen and (min-width: 768px) {
    background: none;
    padding-top: 0px;
    box-shadow: none;
    margin-top: 0px;
  }
`;

export const MobileContainer = styled.div`
  border-radius: 30px 30px 0 0; /* Top-left and top-right corners only */
  background: #fff;
  height: auto;
  box-shadow: 0px -3px 16px 0px rgba(0, 0, 0, 0.10);
  min-height: calc(100vh - 165px);

  @media only screen and (min-width: 768px) {
    border-radius: initial; /* Reset border-radius */
    background: none;
    box-shadow: none;
    margin-top: 0px;
  }
`;
