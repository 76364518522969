import React, { useState } from 'react';
import styled from "styled-components";
import { useRegisterContext } from '../Context';
import { Alert, Button, Form } from 'antd';
import { FormItem, InputTextFields } from '../components/InputField.styled';
import { getCode } from 'services/citizen';
import { useNavigate } from 'react-router-dom';

const Container = styled.div``
const StyledAlert = styled(Alert)`
  align-items: center;
`

const CodeForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { login } = useRegisterContext();
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const isValid = await getCode(values.code);
      setLoading(false);
      if (isValid) {
        login(values.code);
        navigate('/agreement');
      } else {
        setError('Code Already Used or Invalid');
      }
    } catch (error) {
      setLoading(false);
      setError(error?.detail || 'Code Already Used or Invalid');
      console.warn('error', error);
    }
  };

  const onFinishFailed = errorInfo => {
    setError('There was an error');
    console.error("Failed:", errorInfo);
  };

  return (
    <Container>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          minWidth: "90%",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          code: ''
        }}
      >
        <FormItem
          name="code"
          label="User Code"
          rules={[
            {
              required: true,
              message: "Enter User Code",
            },
          ]}>
          <InputTextFields
            name="code"
            placeholder="Enter User Code"
            onChange={e =>
              form.setFieldsValue((values) => ({
                ...values,
                name: e.target.value
              }))
            }
          />
        </FormItem>

        {
          error && (
            <Form.Item>
              <StyledAlert description={error} type="error" closable onClose={() => setError('')} />
            </Form.Item>
          )
        }

        <Button className="mobile-register-btn" htmlType="submit" loading={isLoading}>
          Register
        </Button>
      </Form>
    </Container>
  );
}
export default CodeForm;