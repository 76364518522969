import { Button } from "antd";
import { styled } from "styled-components";

const StyledButton = styled(Button)`
  display: flex;
  height: 43px;
  padding: 8.821px 16px 7.718px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FF7103;
  border-color: #FF7103;
  border-style: none;
  span {
    color: #ffff;
  }

  &.disabled{
    border-radius: 8px;
    background: #C7C7C7;
  }
`;

export default StyledButton;
