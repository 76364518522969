import { Row } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { styled } from "styled-components";
import { useSocialServiceContext } from "../Context";
import { format, parseISO } from "date-fns";
import * as CurrencyFormat from "react-currency-format";

const HistoryContainer = styled(Row)`
  height: 90px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 16px;
  background: #F7F7F7;
  .fullWidth {
    width: 100%;

    
    @media only screen and (min-width: 900px) {
      color: #FFF;
      span {
        color: #FFF;
      }
    }
  }
`;
const ProgramName = styled.span`
  color: #000;
  font-family: Gilmer-Bold;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
  @media only screen and (min-width: 900px) {
    color: #FFF;
  }
`;

const AgencyAcronym = styled.span`
  color: #0038A8;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  @media only screen and (min-width: 900px) {
    color: #FFF;
  }
`;

const SocialServiceDate = styled.span`
  color: #35343D;

  font-family: Gilmer-Bold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  @media only screen and (min-width: 900px) {
    color: #FFF;
  }
`;

const SocialServiecProposedAmount = styled.span`
  span{
    color: #35343D;

    font-family: Gilmer-Bold;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    @media only screen and (min-width: 900px) {
      color: #FFF;
    }
  }
`;

const ProgramDetail = styled.div`
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Vertically center the child elements */
  flex-wrap: wrap; /* Allow the items to wrap as needed */
  @media only screen and (min-width: 900px) {
    color: #FFF;
  }
`;
const StyledTitle = styled(Title)`
  color: #4D4D4D !important;

  font-family: Gilroy-Bold;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.002px;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const HistoryItem = ({
  citizen_program_id,
  proposed_amount,
  created_at,
  agency,
  program: { id, name },
  overrides,
  program_type,
}) => {
  const renderProposedAmount = () => {
    if (proposed_amount) {
      return (
        <CurrencyFormat
          thousandSeparator={true}
          displayType={"text"}
          prefix={"PHP "}
          value={proposed_amount}></CurrencyFormat>
      );
    }


    switch (program_type) {
      case 1: {
        return (
          <CurrencyFormat
            thousandSeparator={true}
            displayType={"text"}
            prefix={"₱"}
            value={proposed_amount}></CurrencyFormat>
        );
      }
      case 2: {
        return overrides?.details || "No Details";
      }
      case 3: {
        return "Attendance";
      }
      default: {
        return "No Proposed Amount";
      }
    }
  };

  return (
    <HistoryContainer className="fullWidth" key={citizen_program_id}>
      <ProgramName className="fullWidth">
        <AgencyAcronym>{agency.id !== 33 ? agency.acronym : overrides.agency}</AgencyAcronym>: {id !== 110 ? name : overrides?.program}
      </ProgramName>
      <ProgramDetail>
        <SocialServiceDate>{format(parseISO(created_at), "MMMM d, yyyy | h:mma")}</SocialServiceDate>
      </ProgramDetail>
      <SocialServiecProposedAmount className="fullWidth">{renderProposedAmount()}</SocialServiecProposedAmount>
    </HistoryContainer>
  );
};

const History = () => {
  const { history: histories } = useSocialServiceContext();

  return (
    <>
      <Row>
        <StyledTitle level={3}>Social Service History</StyledTitle>
      </Row>
      {histories?.map(item => (
        <HistoryItem key={item.citizen_program_id} {...item} />
      ))}
    </>
  );
};

export default History;
