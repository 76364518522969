import styled from "styled-components";
import { Button as AntdButton, Steps, Form } from "antd";

const { Step } = Steps;

const FormContainer = styled(Form)`
  @media only screen and (min-width: 768px) {
    padding: 2.5rem;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    width: 60%;
  }

  .step-component-title {
    height: 3rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    @media only screen and (min-width: 768px) {
      font-size: 1.75vw;
      justify-content: flex-start;
      margin-bottom: 2rem;
    }
  }
`;

const WizardContainer = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .step-component-container {
    padding: 5px 20px;

    @media only screen and (min-width: 768px) {
      width: 100%;
      height: fit-content;
      padding: 5rem;
      margin-left: 25vw;
      display: flex;
      justify-content: center;
    }
  }
`;

const MobileButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    margin-top: 1.5rem;
    text-align: right;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    button {
      background-color: #0038A8 !important;
      border: none;
    }
  }
`;

const Button = styled(AntdButton)`
    display: flex;
    height: 43px;
    padding: 8.821px 16px 7.718px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    width: 100%;
    margin: 5px 0px;

    &.ant-btn-primary { 
        background-color: #FF7103;
        color: #FFFFFF; 
        border-color: #FF7103;

        span {
            color: #FFFFFF;
        }

        &:hover, &:focus {
          background-color:#FF7103;
          border-color:#FF7103;
        }
 
    }
   
    &.ant-btn-default {
        background-color: #BCBCBC;
        border-color: #BCBCBC;

        span {
            color: #000000;
        }

        &:hover, &:focus {
            background-color: #EBEBEB;
            border-color: #EBEBEB;
        }
    }

    &.ant-btn-social-service {
        background-color: #FF6E03;
        border-color: #FF6E03;


        span {
            color: #FFFFFF;
            font-weight: bold;
        }

        &:hover, &:focus {
            background-color: #FF7916;
            border-color: #FF7916;
        }
    }

    @media only screen and (min-width: 768px) {
        border-radius: 3px;
        width: 40%;
        margin: 5px 30px;
    }

    
`;

const Sidebar = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    background: rgba(234, 234, 234, 0.28);
    height: 100%;
    width: 25vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5rem;
    border-right: 2px solid rgba(0, 0, 0, 0.125);

    .logo-sidebar {
      display: flex;
      margin: 0 auto;
      margin-top: 5rem;
      width: 15rem;
    }
  }
`;

const Topbar = styled.div`
  display: flex;
  width: 100%;

  p {
    color: #0038A8;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
  }

  @media only screen and (min-width: 768px) {
    height: 10rem;
    font-size: 2.5vw;
    justify-content: center;
    align-items: center;
  }
`;

const MobileWizardStepsContainer = styled.div`
  width: 100%;
  height: 165px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #fff;
  position: relative;
  z-index: 9999;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MobileWizardSteps = styled(Steps)`
  width: 70%;
  margin: 0 auto;

  .ant-steps-item-container {
    display: flex;

    .ant-steps-item-tail,
    .ant-steps-item-content {
      display: none;
    }

    .ant-steps-item-icon {
      background-color: #e8e9f3;
      border: 14px solid #e8e9f3;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-steps-icon {
        color: #0038A8;
        font-size: 12px;
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: #0038A8;
      border-color: #0038A8;

      .ant-steps-icon {
        color: #fff;
      }
    }
  }
`;

const WizardSteps = styled(Steps)`
  width: 60%;
  display: flex;
  margin: 0 auto;

  .ant-steps-item-container {
    display: flex;
    margin: 0 auto;
    width: 100%;

    .ant-steps-item-tail,
    .ant-steps-item-content {
    }

    .ant-steps-item-icon {
      background-color: #e8e9f3;
      border: 14px solid #e8e9f3;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-steps-icon {
        color: #0038A8;
        font-size: 12px;
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: #0038A8;
      border-color: #0038A8;

      .ant-steps-icon {
        color: #fff;
      }
    }
  }
`;

const WizardStep = styled(Step)``;

export {
  WizardContainer,
  Button,
  MobileButtonContainer,
  ButtonContainer,
  FormContainer,
  Sidebar,
  Topbar,
  MobileWizardStepsContainer,
  MobileWizardSteps,
  WizardSteps,
  WizardStep,
};
