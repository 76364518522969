import React, { useContext, useState } from "react";
import { UploadImageAvatar, UploadButton, UploadImageStepContainer } from "../../components/Register.styled";
import defaultAvatar from "../../../../assets/default_avatar.png";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload, Spin } from "antd";
import { NewCitizenContext } from "../NewCitizenContext";
import { uploadCitizenPhoto } from "../../../../services/public_registration.service";

const UploadImage = () => {
  const { state, stateDispatchMain } = useContext(NewCitizenContext);

  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validImageFormats = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

  const uploadMedia = async ({ file, onError }) => {
    try {
      setIsLoading(true);
      if (!validImageFormats.includes(file?.type)) {
        setTimeout(() => {
          onError("error");
        }, 100);
        return;
      }

      const uploadedMedia = await uploadCitizenPhoto(file);

      stateDispatchMain({
        key: "citizenDetails",
        payload: {
          ...state.citizenDetails,
          profile: uploadedMedia.url,
        },
      });
      setIsLoading(false);
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const props = {
    accept: "image/*",
    name: name,
    customRequest: uploadMedia,
  };

  return (
    <UploadImageStepContainer>
      <p style={{ padding: "0 10px" }}>
        Mangyaring kumuha ng malinaw na larawan ng iyong mukha. Tiyaking buong mukha mo ay malinaw na makikita sa
        larawan.
      </p>
      <div style={{ marginBottom: "15px" }}>
        {isLoading ? <Spin /> : <UploadImageAvatar src={image || defaultAvatar} />}
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Upload {...props} showUploadList={false}>
          <UploadButton
            type="dashed"
            icon={<CloudUploadOutlined style={{ fontSize: "30px", fontWeight: "bold", marginBottom: "10px" }} />}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px 25px",
              borderRadius: "20px",
              borderColor: "#262261",
            }}>
            Upload File
          </UploadButton>
        </Upload>
      </div>
    </UploadImageStepContainer>
  );
};

export default UploadImage;
