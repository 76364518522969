import React from "react";
import { RegisterHomePageContainer } from "../components/Register.styled";
import CodeForm from "../CodeForm";


const RegisterHomepage = () => {

  return (
    <RegisterHomePageContainer>
      <div className="left-side-container">
        <div className="text-container">
        </div>
      </div>
      <div className="right-side-container">
        <div className="text-container">
          <div className="logo-banner-container">
            <img src="/static/images/logo.svg" className="logo" />
            
          </div>
          <h2><strong>Senior Inspired Government & Lifestyle App</strong></h2>
            Tungo sa Masiglang Republika
         
        </div>
        <div className="text-container-desktop">
          <div className="logo-banner-container">
            <img src="/static/images/logo.svg" className="logo" />
          </div>
          <h2>Mag rehistro na para sa</h2>
          <h2><strong>Senior Inspired Government & Lifestyle App</strong></h2>
        </div>
        <div className="button-container">
          <CodeForm />
        </div>
          <img src="/static/images/bottom-logo.svg" className="bottom-logo-image" />
      </div>
    </RegisterHomePageContainer>
  );
};

export default RegisterHomepage;
